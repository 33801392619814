.header-section{
    background-color: var(--white-color);
    .header-section__logo{
        padding: 0rem;
        text-align: center;
    }
    .header-section__social-media{
        padding: 0rem 0rem 1.8rem 0rem;
        margin-top: 2.8rem;
        ul{
            display: flex;
            justify-content: center;            
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            list-style: none;
            li{
                margin-left: 1.2rem;
                margin-bottom: 2.8rem;
                a{
                    text-decoration: none;
                    color: var(--white-color);
                    i{
                        padding: 0.8rem;
                        background-color: var(--primary-color);
                        border-radius: 50%;
                        font-size: 1.8rem;
                        height: 50px;
                        width: 50px;
                    }  
                    .icon-youtube{
                        font-size: 1.6rem;
                        padding: 1.0rem 0.8rem;
                    }  
                    .icon-whatsapp{
                        padding: 0.8rem 1.0rem;
                    }  
                    .icon-linkedin{
                        padding: 0.8rem 1.0rem;
                    } 
                    .icon-instagram{
                        padding: 0.8rem 1.0rem;
                    }   
                    .icon-facebook:hover{
                        background-color: #3b5998;
                    }
                    .icon-instagram:hover{
                        background-color: #C13584;
                    }                   
                    .icon-twitter:hover{
                        background-color: #00acee;
                    } 
                    .icon-linkedin:hover{
                        background-color: #0e76a8;
                    } 
                    .icon-youtube:hover{
                        background-color: #c4302b ;
                    }
                    .icon-whatsapp:hover{
                        background-color: #25D366;
                    }                                                              
                }
            }
        }
    }
    .header-section__menu{        
        padding: 1.1rem;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f5f6f6+0,dbdce2+21,b8bac6+49,dddfe3+80,f5f6f6+100;Grey+Pipe */
background: #f5f6f6; /* Old browsers */
background: -moz-linear-gradient(top,  #f5f6f6 0%, #dbdce2 21%, #cecfd4 49%, #dddfe3 80%, #f5f6f6 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #f5f6f6 0%,#dbdce2 21%,#cecfd4 49%,#dddfe3 80%,#f5f6f6 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #f5f6f6 0%,#dbdce2 21%,#cecfd4 49%,#dddfe3 80%,#f5f6f6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f6f6', endColorstr='#f5f6f6',GradientType=0 ); /* IE6-9 */

        .menu-movil{
            display: flex;
            justify-content: center;
            text-decoration: none;
            color: var(--white-color);
            margin-bottom: initial;
            .icon-bars{
                padding: 0.8rem 1.0rem;
                background-color: var(--secondary-color);
                border-radius: 50%;
                font-size: 2.8rem;
            }
        }
        .nav-bar{           
            .nav-menu{
                display: none;
                list-style: none;
                justify-content: center;
                align-items: center;
                align-content: center;
                li{
                    padding: 1.8rem 0rem;         
                    border-bottom: 2px solid black;
                    a{ 
                        color: black;
                        text-decoration: none;
                    }   
                    ul {
                        display: none;
                        opacity: 1;
                        li {
                          list-style: none;
                          line-height: 3.6rem;
                          border-bottom: 1px solid var(--black-color);
                          padding: 0.8rem 0rem 0.8rem 0.8rem;
                          a {
                            font-size: 1.6rem;
                            border-left: initial;
                          }
                          a:last-child {
                            border-right: initial;
                          }
                          a:hover {
                            background: initial;
                            color: var(--white-color);
                          }
                        }
                        li:hover {
                          background: var(--secondary-color);
                          a{                            
                            color: var(--white-color)!important;
                          }
                        }
                      }                                    
                }
                li:hover ul {
                    background: var(--white-color);
                    display: block;
                    margin-top: 1.1rem;
                    width: auto;
                    height: auto;
                    border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
                }
                .header-display-block {
                  display: block;
                  opacity: 1 !important;
                  height: auto !important;
                  transition: all 0.5s;
                }
            }
            .header-display-block {
                display: block;
                height: auto!important;
                opacity: 1!important;
                -webkit-transition: all .5s;
                -o-transition: all .5s;
                transition: all .5s;
            }             
        }
    }
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
}

// Small devices 
@media (min-width: 576px) {

}

// Medium devices 
@media (min-width: 768px) {

}

// Large devices
@media (min-width: 992px) {
    .header-section{
        .header-section__social-media{
            ul{
                justify-content: flex-end;
                li{
                    margin-bottom: initial;
                }
            }
        }
        .header-section__menu{        
            padding: 0.8rem;
            border-top: 1px solid black;
            border-bottom: 1px solid black;
            .menu-movil{
                display: none;
            }            
            .nav-bar{
                .nav-menu{
                    display: flex;
                    list-style: none;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    li{
                        padding: initial;         
                        border-bottom: initial;
                        a{           
                            padding: 1.2rem 2.8rem;
                            border-left: 1px solid black;
                            text-decoration: none;
                        }      
                        a:hover{
                            background-image: url(../img/menu-bar.jpg);
                            background-position: top;
                            background-repeat: repeat-x;
                        }  
                        ul {
                            display: none;
                            opacity: 1;
                            li {
                              list-style: none;
                              line-height: 3.6rem;
                              border-bottom: 1px solid var(--gray-color);
                              padding: 0.8rem 0rem;
                              a {
                                font-size: 1.6rem;
                                border-left: initial;
                              }
                              a:last-child {
                                border-right: initial;
                              }
                              a:hover {
                                background: initial;
                                color: var(--white-color);
                              }
                            }
                            li:hover {
                              background: var(--secondary-color);
                            }
                        }                                    
                    }
                    li:hover ul {
                        background: var(--white-color);
                        display: block;
                        margin-top: 0.8rem;
                        width: auto;
                        height: auto;
                        position: absolute;
                        border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
                    }                                         
                    li:last-child{
                        a{
                            border-right: 1px solid black;
                        }
                    }
                }
            }
        }        
    }
}


// Extra large devices
@media (min-width: 1200px) {

}

// Extra Extra large devices 
@media (min-width: 1400px) {

}