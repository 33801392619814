.header-section {
  background-color: var(--white-color);
}
.header-section .header-section__logo {
  padding: 0rem;
  text-align: center;
}
.header-section .header-section__social-media {
  padding: 0rem 0rem 1.8rem 0rem;
  margin-top: 2.8rem;
}
.header-section .header-section__social-media ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  list-style: none;
}
.header-section .header-section__social-media ul li {
  margin-left: 1.2rem;
  margin-bottom: 2.8rem;
}
.header-section .header-section__social-media ul li a {
  text-decoration: none;
  color: var(--white-color);
}
.header-section .header-section__social-media ul li a i {
  padding: 0.8rem;
  background-color: var(--primary-color);
  border-radius: 50%;
  font-size: 1.8rem;
  height: 50px;
  width: 50px;
}
.header-section .header-section__social-media ul li a .icon-youtube {
  font-size: 1.6rem;
  padding: 1rem 0.8rem;
}
.header-section .header-section__social-media ul li a .icon-whatsapp {
  padding: 0.8rem 1rem;
}
.header-section .header-section__social-media ul li a .icon-linkedin {
  padding: 0.8rem 1rem;
}
.header-section .header-section__social-media ul li a .icon-instagram {
  padding: 0.8rem 1rem;
}
.header-section .header-section__social-media ul li a .icon-facebook:hover {
  background-color: #3b5998;
}
.header-section .header-section__social-media ul li a .icon-instagram:hover {
  background-color: #C13584;
}
.header-section .header-section__social-media ul li a .icon-twitter:hover {
  background-color: #00acee;
}
.header-section .header-section__social-media ul li a .icon-linkedin:hover {
  background-color: #0e76a8;
}
.header-section .header-section__social-media ul li a .icon-youtube:hover {
  background-color: #c4302b;
}
.header-section .header-section__social-media ul li a .icon-whatsapp:hover {
  background-color: #25D366;
}
.header-section .header-section__menu {
  padding: 1.1rem;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f5f6f6+0,dbdce2+21,b8bac6+49,dddfe3+80,f5f6f6+100;Grey+Pipe */
  background: #f5f6f6;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f5f6f6 0%, #dbdce2 21%, #cecfd4 49%, #dddfe3 80%, #f5f6f6 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f5f6f6 0%, #dbdce2 21%, #cecfd4 49%, #dddfe3 80%, #f5f6f6 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f5f6f6 0%, #dbdce2 21%, #cecfd4 49%, #dddfe3 80%, #f5f6f6 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f5f6f6", endColorstr="#f5f6f6",GradientType=0 );
  /* IE6-9 */
}
.header-section .header-section__menu .menu-movil {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: var(--white-color);
  margin-bottom: initial;
}
.header-section .header-section__menu .menu-movil .icon-bars {
  padding: 0.8rem 1rem;
  background-color: var(--secondary-color);
  border-radius: 50%;
  font-size: 2.8rem;
}
.header-section .header-section__menu .nav-bar .nav-menu {
  display: none;
  list-style: none;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.header-section .header-section__menu .nav-bar .nav-menu li {
  padding: 1.8rem 0rem;
  border-bottom: 2px solid black;
}
.header-section .header-section__menu .nav-bar .nav-menu li a {
  color: black;
  text-decoration: none;
}
.header-section .header-section__menu .nav-bar .nav-menu li ul {
  display: none;
  opacity: 1;
}
.header-section .header-section__menu .nav-bar .nav-menu li ul li {
  list-style: none;
  line-height: 3.6rem;
  border-bottom: 1px solid var(--black-color);
  padding: 0.8rem 0rem 0.8rem 0.8rem;
}
.header-section .header-section__menu .nav-bar .nav-menu li ul li a {
  font-size: 1.6rem;
  border-left: initial;
}
.header-section .header-section__menu .nav-bar .nav-menu li ul li a:last-child {
  border-right: initial;
}
.header-section .header-section__menu .nav-bar .nav-menu li ul li a:hover {
  background: initial;
  color: var(--white-color);
}
.header-section .header-section__menu .nav-bar .nav-menu li ul li:hover {
  background: var(--secondary-color);
}
.header-section .header-section__menu .nav-bar .nav-menu li ul li:hover a {
  color: var(--white-color) !important;
}
.header-section .header-section__menu .nav-bar .nav-menu li:hover ul {
  background: var(--white-color);
  display: block;
  margin-top: 1.1rem;
  width: auto;
  height: auto;
  border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
}
.header-section .header-section__menu .nav-bar .nav-menu .header-display-block {
  display: block;
  opacity: 1 !important;
  height: auto !important;
  transition: all 0.5s;
}
.header-section .header-section__menu .nav-bar .header-display-block {
  display: block;
  height: auto !important;
  opacity: 1 !important;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}

@media (min-width: 992px) {
  .header-section .header-section__social-media ul {
    justify-content: flex-end;
  }
  .header-section .header-section__social-media ul li {
    margin-bottom: initial;
  }
  .header-section .header-section__menu {
    padding: 0.8rem;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
  .header-section .header-section__menu .menu-movil {
    display: none;
  }
  .header-section .header-section__menu .nav-bar .nav-menu {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .header-section .header-section__menu .nav-bar .nav-menu li {
    padding: initial;
    border-bottom: initial;
  }
  .header-section .header-section__menu .nav-bar .nav-menu li a {
    padding: 1.2rem 2.8rem;
    border-left: 1px solid black;
    text-decoration: none;
  }
  .header-section .header-section__menu .nav-bar .nav-menu li a:hover {
    background-image: url(../img/menu-bar.jpg);
    background-position: top;
    background-repeat: repeat-x;
  }
  .header-section .header-section__menu .nav-bar .nav-menu li ul {
    display: none;
    opacity: 1;
  }
  .header-section .header-section__menu .nav-bar .nav-menu li ul li {
    list-style: none;
    line-height: 3.6rem;
    border-bottom: 1px solid var(--gray-color);
    padding: 0.8rem 0rem;
  }
  .header-section .header-section__menu .nav-bar .nav-menu li ul li a {
    font-size: 1.6rem;
    border-left: initial;
  }
  .header-section .header-section__menu .nav-bar .nav-menu li ul li a:last-child {
    border-right: initial;
  }
  .header-section .header-section__menu .nav-bar .nav-menu li ul li a:hover {
    background: initial;
    color: var(--white-color);
  }
  .header-section .header-section__menu .nav-bar .nav-menu li ul li:hover {
    background: var(--secondary-color);
  }
  .header-section .header-section__menu .nav-bar .nav-menu li:hover ul {
    background: var(--white-color);
    display: block;
    margin-top: 0.8rem;
    width: auto;
    height: auto;
    position: absolute;
    border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
  }
  .header-section .header-section__menu .nav-bar .nav-menu li:last-child a {
    border-right: 1px solid black;
  }
}